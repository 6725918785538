import { Link } from '@remix-run/react'

import { Container } from '~/components'
import { LinkList } from '~/features/common/link-list'

type Link = React.ComponentProps<typeof LinkList>['links'][0]

// const postYourOfficeLinks: Link[] = [
//   { href: '/real-estate-property-info', text: '不動産・管理会社向け情報' },
//   { href: '/contact-about-listing', text: '掲載のお問い合わせ' },
// ]

const officeMapInfoLinks: Link[] = [
  { href: '/faq', text: 'よくある質問' },
  {
    href: '/contact',
    text: 'お問い合わせ',
    target: '_blank',
    rel: 'noreferrer',
  },
  { href: '/privacy-policy', text: 'プライバシーポリシー' },
  { href: '/terms', text: '利用規約' },
  { href: 'https://trancereal.co.jp', text: '運営会社' },
]

type LinkSectionProps = {
  title: string
  links: Link[]
  ariaLabel?: string
}

const LinkSection = ({ title, links, ariaLabel }: LinkSectionProps) => {
  return (
    <div aria-label={ariaLabel}>
      <h3 className="mb-4 text-sm font-normal">{title}</h3>
      <LinkList links={links} textSize="sm" />
    </div>
  )
}

const BottomNavigation = () => {
  return (
    <nav className="flex w-full flex-col items-center justify-center bg-brand-bg pb-4 text-xs">
      <div className="flex items-center gap-x-4">
        <img
          alt="footer-logo"
          aria-label="footer-logo"
          className="size-20"
          src="/logo.svg"
        />
        <h2
          aria-label="footer-logo-title"
          className="text-xs font-bold text-brand"
        >
          シェアオフィス
          <br />
          コワーキングスペース検索サイト
        </h2>
      </div>
      <span className="text-xs text-brand">
        Copyright © officeMAP. All Rights Reserved.
      </span>
    </nav>
  )
}

export const TopFooter = () => {
  return (
    <footer
      aria-label="footer"
      className="flex w-full flex-col justify-between gap-y-4 border-t border-grayBorder bg-white text-sm"
    >
      <Container className="pb-6 pt-6">
        <div className="flex flex-col gap-y-10">
          {/* <LinkSection
            ariaLabel="post-your-office-links"
            links={postYourOfficeLinks}
            title="officeMAPにオフィスを掲載しませんか？"
          /> */}
          <LinkSection
            ariaLabel="office-map-info-links"
            links={officeMapInfoLinks}
            title="officeMAPに関する情報"
          />
        </div>
      </Container>
      <BottomNavigation />
    </footer>
  )
}
