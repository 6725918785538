import { FC } from 'react'

import { cva } from 'class-variance-authority'

import { Link } from '~/components'
import { twMerge } from '~/lib/utils'

const textVariants = cva('', {
  variants: {
    textSize: {
      sm: 'text-sm',
      base: 'text-base',
    },
  },
  defaultVariants: {
    textSize: 'base',
  },
})

type LinkListProps = {
  links: {
    text: string
    href?: string
    target?: string
    rel?: string
  }[]
  textSize?: 'sm' | 'base'
  className?: string
  ariaLabel?: string
}

export const LinkList: FC<LinkListProps> = ({
  links,
  textSize = 'base',
  className,
  ariaLabel,
}) => {
  return (
    <ul
      aria-label={ariaLabel}
      className={twMerge('flex flex-wrap gap-4', className)}
    >
      {links.map(({ text, href, target, rel }) => (
        <li
          className="border-r border-r-grayBorder pr-4 last:border-0 last:pr-0"
          key={text}
        >
          <Link
            className={textVariants({ textSize })}
            href={href}
            rel={rel}
            target={target}
          >
            {text}
          </Link>
        </li>
      ))}
    </ul>
  )
}
